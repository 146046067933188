import $vuetify from 'vuetify/es5/locale/fr'

export default {
  generic: {
    ok: 'OK',
    no: 'Non',
    yes: 'Oui',
    save: 'Sauvegarder',
    saved: 'Sauvé',
    saving: 'Sauvegarde en cours',
    saveNow: 'Sauvegarder maintenant',
    loading: 'Chargement',
    success: 'Réussie',
    error: 'Erreur',
    errorMsg: "Une erreur s'est produite",
    cancel: 'Annuler',
    search: 'Chercher',
    searchNoResults: "Aucun résultat pour '{search}'",
    update: 'Mettre à jour',
    remove: 'Supprimer',
    submit: 'Envoyer',
    gotIt: 'Compris!',
    apply: 'Appliquer',
    menu: 'Menu',
    back: 'Retour',
    more: 'Plus',
    none: 'Pas',
    close: 'Fermer',
    route: 'Voie',
    routes: 'Voies',
    thisRoute: 'cette voie',
    climbing: 'Grimper',
    rope: 'Corde',
    boulder: 'Bloc',
    boulders: 'Blocs',
    thisBoulder: 'ce bloc',
    bouldering: 'Bloc',
    settings: 'Paramètres',
    readMore: 'Lire plus',
    details: 'Détails',
    today: "Aujourd'hui",
    tomorrow: 'Demain',
    total: 'Total',
    copied: 'Copié',
    comingSoon: 'À venir',
    linkCopiedMsg: 'Lien copié',
    noData: 'Pas de données disponibles',
    refresh: 'Actualiser',
    offlineMsg: 'Vous êtes hors ligne.',
    offlineMsgSave: 'Les modifications seront enregistrées lorsque vous serez en ligne.',
    offlineMsgNotSaved: 'Vos modifications ne seront pas enregistrées.',
    badConnection: 'Mauvaise connexion',
    pagination: {
      frst: 'Première page',
      prev: 'Page précédente',
      next: 'Page suivante',
      last: 'Dernière page',
    },
  },
  inputRules: {
    required: 'Obligatoire',
    minLength: 'Doit contenir au moins {length} caractères',
    maxLength: 'Doit contenir au plus {length} caractères',
    minVal: 'Doit être au moins {minVal}',
    maxVal: 'Doit être au plus {maxVal}',
    emailInvalid: 'E-mail invalide',
    shouldMatchPwd: 'Doit correspondre au mot de passe',
    dateInvalid: 'Date invalide',
    timeInvalid: 'Heure invalide',
  },
  sidenav: {
    profilePic: 'Photo de profil',
    withoutName: 'Utilisateur sans nom',
    atGym: 'À {gymName}',
    profile: 'Profil',
    myProfile: 'Mon profil',
    switchGym: 'Changer de salle',
    selectGym: 'Sélectionnez salle',
    signOut: 'Déconnecter',
    signIn: 'Connecter',
    closeApp: "Fermer l'application",
    routes: 'Voies',
    boulders: 'Blocs',
    dashboard: 'Tableau de bord',
    competitions: 'Compétitions',
    myGym: 'Ma salle',
    reservations: 'Réservations',
    inbox: 'Boîte de réception',
    editRoutes: 'Éditer voies',
    editBoulders: 'Éditer blocs',
    editCompetitions: 'Éditer compétitions',
    communications: 'Communications',
    statistics: 'Statistiques',
    gym: 'Salle',
    monitoring: 'Surveillance',
    help: 'Aide',
    aboutUs: 'À propos de nous',
    contact: 'Contact',
    sendFeedback: 'Laisser un commentaire',
    toc: 'Conditions de service',
  },
  auth: {
    loginTitle: 'Se connecter | TopLogger',
    loginDescription: "Connectez-vous à l'application Web TopLogger ou créez un compte.",
    resetPwdTitle: 'Réinitialiser le mot de passe | TopLogger',
    resetPwdDescription: 'Réinitialiser le mot de passe pour TopLogger',
    unauthorized: 'Non autorisé',
    login: 'Se connecter',
    signIn: 'Se connecter',
    guestAccount: 'Compte invité',
    resetPwd: 'Réinitialiser le mot de passe',
    password: 'Mot de passe',
    passwordConfirm: 'Récupérer mot de passe',
    newsletters: "S'inscrire à la newsletter",
    forgotBtn: 'Mot de passe oublié ?',
    forgotHeader: 'Avez-vous oublié votre mot de passe ?',
    forgotExpl:
      'Pas de soucis. Entrez simplement votre adresse e-mail et nous vous enverrons un e-mail pour réinitialiser votre mot de passe.',
    remember: 'Tu le connais encore ?',
    signUp: "S'inscire",
    google: "S'inscrire avec Google",
    facebook: "S'inscrire avec Facebook",
    createAccount: 'Créer un compte',
    createsAccount: 'Crée automatiquement un compte lié à votre email',
    haveAccount: 'Déjà un compte ?',
    msgLoggedIn: 'Connecté',
    msgLoggedOut: 'Déconnecté',
    msgSentConfirm: 'Un e-mail vous sera envoyé pour confirmation.',
    msgSentReset: 'Un e-mail vous sera envoyé pour réinitialiser votre mot de passe.',
    msgPwdReset: 'Votre mot de passe a été mis à jour.',
    msgSentConfirmNew: 'Un e-mail sera envoyé à votre nouvelle adresse pour confirmation.',
    msgPwdUpdated: 'Votre mot de passe a été mis à jour.',
    loginToSaveProgress: 'Vous devez être connecté pour que vos progrès soient enregistrés',
    actionRequiresLogin: 'Vous devez être connecté pour effectuer cette action.',
  },
  profile: {
    title: 'Votre profil | TopLogger',
    description: 'Modifier votre profil et vos paramètres TopLogger.',
    info: {
      changePicture: 'Modifier la photo',
      zoomToCrop: 'Zoomer et glisser pour recadrer',
      upload: 'Télécharger',
      socialMedia: 'Réseaux sociaux',
      changeFile: 'Modifier fichier',
      chooseFile: 'Sélectionner fichier',
      firstName: 'Prénom',
      lastName: 'Nom',
      male: 'Homme',
      female: 'Femme',
      other: 'Autre',
      country: 'Pays',
      city: 'Ville',
      dateBirth: 'Date de naissance',
      hideDateBirth: 'Cacher la date pour les autres utilisateurs',
      newsletters: 'Recevoir la newsletter',
      anonymous: 'Anonyme',
      length: 'Taille (cm)',
      weight: 'Poids (kg)',
      email: 'E-mail',
      changeMail: 'Modifier email',
      enterNewMail: 'Saisir votre nouvelle adresse e-mail',
      newEmail: 'Nouvel e-mail',
    },
    password: {
      changePwd: 'Modifier le mot de passe',
      newPwd: 'Nouveau le mot de passe',
      confirmNewPwd: 'Confirmer le mot de passe',
    },
    preferences: {
      preferences: 'Préférences',
      language: 'Langue',
      anonymousSuffix: 'Anonyme dans le classement de la salle',
      genderSetToast: "Pour apparaître dans les classements, votre sexe a été défini sur 'Homme'.",
      anonymousSetToast: 'Vous êtes maintenant anonyme: le classement nécessite un sexe.',
    },
    notifications: {
      notifications: 'Notifications',
      channels: 'Canaux',
      push: 'Push',
      newRoutes: 'Nouvelles voies',
      newBoulders: 'Nouveaux blocs',
      news: 'Nouvelles',
      competitions: 'Compétitions',
      restrictRange: 'Limiter le niveau de difficulté',
      onlyRoutesBetween: 'Ne signaler que les nouvelles voies entre',
      onlybouldersBetween: 'Ne signaler que les nouveaux blocs entre',
      selectGym: 'Sélectionnez une salle pour les notifications',
      gymsSelected: '{number} sélectionné',
      noGuarantee:
        'Remarque: l’administrateur de la salle décide quelles mises à jour seront envoyées. Il n’y a donc aucune garantie que les notifications soient regulières.',
    },
  },
  gyms: {
    title: 'Salles disponibles | TopLogger',
    description: 'Toutes les salles disponibles dans TopLogger, choisissez votre salle pour gérer vos blocs et voies.',
    gyms: 'Salles',
    visitedGyms: 'Salles visitées',
    visitedGymsSubhead: 'Durant les six derniers mois',
    gymsNearby: 'Salles dans votre région',
    selectGym: 'Sélectionnez votre salle actuelle',
    goToGym: 'Aller à la salle',
    gymLogo: 'Logo de la salle',
    commingSoon: 'Prochainement...',
    noSwitching: "Pour changer de salle vous devez télécharger l'application vous-même.",
    notAllowed: 'Non authorisé...',
  },
  climbs: {
    title: '{routesBoulders} actuelles à {gymName} | TopLogger',
    descriptionMap:
      "Un fichier interactif contenant tous les {routesBoulders} actuellement disponibles à l'adresse {gymName}.",
    descriptionList: 'Une liste de tous les {routesBoulders} actuellement disponibles chez {gymName}.',
    listView: 'Voir la liste',
    mapView: 'Voir le plan',
    showList: 'Afficher la liste',
    viewMap: 'Voir la carte',
    new: 'Nouveau',
    guestMsg: 'Ceci est un compte invité',
    groups: 'Groupes',
    walls: 'Murs',
    filters: {
      filters: 'Filtres',
      filterBtn: 'Filtrer les voies',
      activeMsg: 'Des filtres sont activés.',
      grade: 'Niveau',
      starRating: 'Étoiles',
      colors: 'Couleurs',
      todo: 'À faire',
      almostRemoved: 'Sort presque',
      forChildren: 'Adaptés aux enfants',
      selectAll: 'Tout sélectionner',
      selectAllFiltered: 'Sélectionner tous les filtres',
      selectAllInView: 'Sélectionner tout en vue',
      deselectAll: 'Tout désélectionner',
      clearFilters: 'Effacer les filtres',
    },
    group: {
      sharedWith: 'Groupe partagé avec:',
      hiddenWall: '1 mur caché par des filtres',
      hiddenWalls: '{count} murs cachés par des filtres',
      hiddenGroup: '1 groupe caché par des filtres',
      hiddenGroups: '{count} groupes cachés par des filtres',
      hiddenRoute: '1 voie cachée par des filtres',
      hiddenRoutes: '{count} voies chachées par des filtres',
      hiddenboulder: '1 bloc caché par des filtres',
      hiddenBoulders: '{count} blocs cachés par des filtres',
    },
    log: {
      done: 'Done',
      top: 'Top',
      flash: 'Flash',
      onsight: 'À vue',
      rp: 'AT',
      fl: 'FL',
      os: 'AV',
      repeat: 'Répétition',
      check: 'Coche',
      tickOff: 'Cocher',
      zones: 'Zones',
      zone: 'Zone',
      myOpinion: 'Niveau estimé',
      tries: 'Essais',
      repeats: 'Répété',
      uncheckMsg: 'Avec ceci vous effacez le log de {timeAgo}.',
      removeAscend: 'Effacer log',
      project: 'Projet',
      getApp: {
        toastTitle: 'Pas ici',
        toastMsg: "Aller sur toplogger.nu pour l'installer sur votre appareil...",
        header: 'Cette app est verrouillée',
        title: "Installer l'app sur votre propre appareil",
        explanation:
          "Si vous souhaitez enregistrer vos progrès, télécharger l'App <i>gratuit</i> sur <span class='tl-text-primary'>toplogger.nu</span> ou utilisez la version web.",
      },
      all: {
        areYouSure: 'Êtes-vous sûr ?',
        update: 'Cela mettra à jour des entrées de {timeAgo}.',
        uncheck: "Vous allez effacer l'entrée par {routeBoulder}, y compris des entrées de {timeAgo}.",
        updateAscends: 'Mise à jour des entrées',
        removeAscends: 'Effacer des entrées',
        logRoutes: 'Loger toutes {count} voies',
        logBoulders: 'Loger tout {count} blocs',
      },
      stats: {
        stats: 'Stats',
        statistics: 'Statistiques',
        dateSet: "Date d'ouverture",
        dateRemoved: 'Date de supression',
        votesTitle: 'Vue des votes',
        votesEmpty: 'Aucun vote pour le niveau.',
        votesTotal: '{votes} total',
        ratingTitle: 'Revue',
        ratingEmpty: 'Aucun commentaire.',
        toppedHeader: 'Réussie par',
        toppedSubhead: 'Utilisateurs anonymes non inclus',
        notToppedBoulder: "Cette vois n'a pas encore été sortie.",
        notToppedRoute: "Ce bloc n'a pas encore été réussi.",
      },
      renew: {
        renewedSoonBoulder: 'Bientôt renouvelé',
        renewedSoonRoute: 'Bientôt renouvelée',
        vote: 'Voter pour le renouvellement',
        unvote: 'Supprimer le vote pour le renouvellement',
        voted: 'Voté (anonyme)',
        votedMsg: 'Vous avez voté pour renouveler {thisRouteBoulder}.',
        unvoted: 'Vote supprimé',
        unvotedMsg: 'Votre vote pour renouveler {thisRouteBoulder} est supprimé.',
      },
      report: {
        report: 'Signaler',
        reportClimb: 'Signaler {thisRouteBoulder}',
        explanation: 'Quelques chose ne pas, prévenir la salle.',
        messageLabel: 'Avertir la salle',
        successMsg: 'Votre message a été envoyé à la salle.',
      },
      help: {
        header: 'Explications',
        buttons: {
          header: 'Boutons',
          subhead: 'Signification des boutons',
          topExpl: "Vous l'avez grimpé.",
          flashExpl: "Vous l'avez grimpé au premier essai.",
          os: 'AV: À vue',
          osExpl: 'Grimpé<sup>1</sup> au premier essai, sans beta<sup>2</sup>.',
          fl: 'FL: Flash',
          flExpl: 'Grimpé<sup>1</sup> au premier essai, mais avec beta<sup>2</sup>.',
          rp: 'AT: Après travail',
          rpExpl: 'Grimpé<sup>1</sup>, mais pas du premier essai.',
          elaboration:
            "Après travail consiste souvent à définir tous les mouvements et à répéter la voie jusqu'à la réussite.",
          sup1: '<sup>1</sup>Réalisé, sorti sans tomber ni support de la corde.',
          sup2:
            "<sup>2</sup>Beta: Informations données sur la voie par d'autres personnes, bouche à oreille, vidéo, etc.",
        },
        stability: {
          header: 'Indicateur de stabilité',
          explanation:
            'Le point sous du niveau est une indication de la stabilité du niveau. Cela peut prendre trois valeurs:',
          unstableText:
            "<strong>Instable</strong>: Il n'y as pas encore assez de votes. Ce niveau peut encore être ajusté.",
          midStableText:
            '<strong>Probablement stable</strong>: Probablement stable… des votes sont nécessaires mais le niveau se stabilise.',
          stableText:
            '<strong>Stable</strong>: De nombreux utilisateurs ont voté pour ce niveau. Peu de chance que cela change.',
        },
        questions: {
          QgradeChange: "Qu'est-ce qui se passe avec mon score quand le niveau change?",
          AgradeChange: 'Pas de souci, il est recalculé immédiatement.',
        },
      },
    },
  },
  dashboard: {
    title: 'TopLogger dashboard pour {gymName}',
    description: 'Classement de la salle, nouvelle voies, vos progrès et statistiques à {gymName}.',
    otherUserMsg: 'Statistiques de {userName}',
    viewClimbs: 'Voir les {routesBoulders}',
    showMine: 'Mes stats',
    showDemoGraph: 'Afficher le graphique',
    showingDemoGraph: 'Graphique de démonstration',
    notEnoughLogged: "Vous n'avez pas été connecté assez longtemps pour qu'un graphique soit généré.",
    guestUser: 'Utilisateur invité',
    statsLoadErrorMsg: 'Impossible de charger les statistique.',
    whatsapp: 'WhatsApp',
    facebook: 'Facebook',
    twitter: 'Twitter',
    link: 'Lien',
    score: {
      subhead: 'Statistiques et progression',
      countHeader: '{routesBoulders} grimpé',
      countHeaderExpl: '{gymName} : Nombres de {routesBoulders} grimpés',
      gradeHeader: '{routeBoulder} niveau',
      gradeHeaderExpl: '{gymName} : Moyenne de votre top 10 durant les 2 dernier mois',
      historyHeader: 'Progression {routeBoulder}',
      historySubhead: 'Nombres de top dans <strong>toutes</strong> les salles.',
      countBarText: '{count} de {total}',
      gradeBarTooltip: 'Encore {percentLeft}% pour obtenir le niveau ',
      effective: 'Durée',
      bonus: 'Bonus',
      points: 'Points',
      average: 'Moyenne',
      bonusExplHeader: 'Sur les points bonus',
      bonusExplOS: '<b>À vue</b> donne 2 plus extra, équivalent à 33,33 points.',
      bonusExplFL: '<b>Flash</b> donne 1 plus extra, équivalent à 16,66 points.',
    },
    ranking: {
      header: 'Classement pour {gymName}',
      noName: 'Pas de nom',
      setNameMsg: 'Introduisez votre nom pour apparaître dans le classement, vous pouvez également rester anonyme.',
      anonymousMsg: "Vous êtes anonyme, vour n'apparaissez pas dans le classement.",
      countHeader: 'Classement Tops',
      countSubhead: 'Le plus de tops par {gymName}',
      gradeHeader: 'Classement par niveau',
      gradeSubhead: 'Basé sur la moyenne du top 10 des deux dernier mois',
      all: 'Tous',
      tooFewRoutesMsg0: "Vous n'avez pas encore fait assez de voies.",
      tooFewRoutesMsg1: "Vous n'avez pas encore fait assez de voies.",
      tooFewRoutesMsg: "Vous n'avez pas encore fait assez de voies.",
      tooFewBouldersMsg0: "Vous n'avez pas encore fait assez de blocs.",
      tooFewBouldersMsg1: "Vous n'avez pas encore fait assez de blocs.",
      tooFewBouldersMsg: "Vous n'avez pas encore fait assez de blocs.",
      logMoreMsg: 'Encore {count} tops pour visualiser leur top 10',
      logRoutesBtn: 'Logger des voies',
      logBouldersBtn: 'Logger des blocs',
      tooFewLogsMsg: 'Basé sur {count} logs',
    },
    oldNew: {
      header: 'Mises à jour',
      subhead: 'Mises à jour sur les {routesBoulders} de votre salle',
      lastIn: 'Dernières ouvertures',
      newClimbs: 'Nouveaux {routesBoulders}',
      firstOut: 'Prochaines suppressions',
      oldClimbs: '{routesBoulders} bientôt enlevée',
      daysLeft: 'Encore {count} jours',
      nothing: 'Rien de prévu !',
    },
  },
  competitions: {
    title: 'Compétition à {gymName} | TopLogger',
    description: 'Une liste de tout les compétitions à {gymName}. Compétitions qui ont eu lieu et leur classement.',
    compTitle: '{competitionName} par {gymName} | TopLogger',
    compDescription: "Résultats de la compétition '{competitionName}' à {gymName}.",
    compClimbsTitle: "Les {routesBoulders} pour '{competitionName}' | TopLogger",
    compClimbsDescMap: 'Un fichier interactif avec tout les {routesBoulders} pour {competitionName}.',
    compClimbsDescList: 'Une liste de tout les {routesBoulders} pour {competitionName}.',
    noCompetitionsMsg: "Pour l'instant il n'y as pas de compétitions à {gymName}...",
    notParticipatingInMsg: 'Competitions don tu ne participe pas encore:',
    competitionResults: 'Résultats',
    competition: 'Competition',
    expiredComp: 'Competition expiré',
    expiredComps: 'Competitions expiré',
    expiredCompsSubhead: 'Voir les resultats',
    noMoreLogging: 'Plus possible de logger pour cette competition.',
    logSubhead: 'Montre les tops depuis le début de {compName} {startDate}',
    expiredToastr: 'Competition expiré',
    expiredToastrMsgJoin: 'La date limite est passée, plus possible de participer',
    expiredToastrMsgQuit: "La date limite est passée, plus possible de s'arrêter",
    participate: 'Participer',
    participated: 'Vous avez participé',
    participating: 'Vous participez',
    subscribed: 'Vous avez enregistré',
    logClimbs: 'Log de monte',
    notStarted: "Ce concours n'a pas encore commencé",
    unranked: 'Non classé',
    approvingMsg: "En attende d'approbation par la salle.",
    approvingMsg2: 'Mais vous pouvez commencer à logger.',
    approvingToastr: "En attende d'approbation",
    approvingToastrMsg: 'Votre demmande doit être approuvée par la salle.',
    approvedToastrMsg: 'Et maintenant allez!',
    genderRequiredMsg: 'Un genre est requis pour la participation',
    ageRequiredMsg: 'Votre âge est requis pour la participation',
    setGender: 'Choisir le genre',
    setAge: "Définir l'âge",
    unsubscribeTitle: 'Se désabonner de {competitionName}',
    unsubscribeMsg: 'Êtes-vous sur de vouloir vous désabonner?',
    unsubscribe: 'Désabonner',
    unsubscribedMsg: "Vous ne participer plus à '{competitionName}'.",
    start: 'Départ',
    deadline: 'Date limite',
    ranking: 'Classement',
    toggleRanking: 'Classement',
    delayedScoresMsg: 'Les scores de cette competition sont recalculés tout les 30 secondes.',
    refresh: 'Renouvler les scores',
    switchPoule: "Pool d'échange",
    selectPoule: 'Sélectionnez poule',
    selectPouleExpl:
      'Ce concours fonctionne avec des classements groupés. Sélectionnez le poule auquel vous souhaitez participer. Cela peut être modifié ultérieurement.',
    help: {
      btn: 'Aide',
      header: 'Sur competitions',
      generalTitle: 'Comment ca marche',
      generalExpl:
        'Une competitions est un classement basé sur les top inscrits dans le temps entre le départ et la fin.',
      generalExpl2:
        "Si vous avez déjà sorti une voie avant le début de la competition, vous devez la répéter pour qu'elle compte pour votre score. Le nombre de points par voie sortis est supérieur à chaque compétition.",
      poulesTitle: 'Classement groupé',
      poulesExpl:
        "Les compétitions peuvent avoir des classements groupés ou des groupes. Cela signifie que vous pouvez participer à une sous-compétition composée uniquement de grimpeurs ayant à peut près le même niveau. Lors de votre inscription, vous verrez apparaître une fenêtre contextuelle dans lequelle vous pourrez entrer votre propre niveau (après travail). L'honnêteté est donc appréciée.",
    },
    info: {
      header: 'Réglements',
      subhead: 'Spécifiquement pour {groupName}',
      rules: 'Regles',
      pricesHeader: 'Prix à gagner',
      scoreSystem: 'Sytème de score',
      points: {
        description:
          'Classement basé sur points, chaque top vaut des point, probablement plus quand il est grimper en une fois.',
        points: '{points} poins',
        bonus: '{points} points bonus',
      },
      pointsByGrade: {
        description:
          "Le nombre de points par voies est lié au degré de la voie, conformément au tableau de conversion des niveaus. S'il y a des zones, chaque zone compte comme un sommet.",
        button: 'Table de conversion des niveau',
        explanation: 'Points en proportion du niveau.',
        efforts:
          'Seulement vos meilleur {efforts} résultats seront utilisés pour calculer votre score, chaque zone conte comme résultat.',
      },
      divBy: {
        description:
          'Chaque top (en zone) raporte des points, bien 1000 divisé par le nombre de grimpeurs qui font la même chose.',
        bonusPercent: '{percentage}% points bonus',
      },
    },
    setGrade: {
      header: 'Fixer voter niveau de participation',
      explanation:
        "Cette competition fonctionne avec des groupes dans lesquels vous êtes classés en fonction de votre niveau d'escalade. Vous vous battez contre des grimpeurs d'un niveau comparable au sein de votre groupe.",
      explanation2:
        'Définissez votre niveau maximum actuel (après travail). Ce niveau sera utilisé pour déterminer votre groupe.:',
      minGradeExplanation: 'Vous avez montrer que ceci est votre niveau minimum: ',
    },
  },
  gym: {
    title: '{gymName} - Information générique | TopLogger',
    description: "Coordonnées heures d'ouverture et une carte pour {gymName}.",
    currentGym: 'Salle courante:',
    contact: 'Contact',
    phone: 'Téléphone',
    parking: 'Parking',
    website: 'Site internet',
    gallery: 'Galerie',
    gallerySubhead: 'Quelques impressions de la salle',
    impression: 'Impression',
    hours: "Heures d'ouverture",
    closed: 'Fermé',
    Monday: 'Lundi',
    Tuesday: 'Mardi',
    Wednesday: 'Mercredi',
    Thursday: 'Jeudi',
    Friday: 'Vendredi',
    Saturday: 'Samedi',
    Sunday: 'Dimanche',
    location: 'Location',
  },
  reservations: {
    title: 'Réservations pour {gymName} | TopLogger ',
    description: 'Trouvez les plages horaires disponibles et faites une réservation pour visiter {gymName}.',
    accountRequiredMsg: "Vous avez besoin d'un compte pour pouvoir réserver un créneau horaire",
    slotsHeader: 'créneaux horaires disponibles',
    selectArea: 'Sélectionnez votre région',
    spotsLeft: '{spots} spots restants',
    dialogTitle: 'Réservez votre créneau horaire',
    notAvailableYet: 'Pas encore disponible',
    available: 'Disponible',
    customerInformation: 'Informations client',
    associate: 'Partenaire',
    addAssociate: 'Ajouter un partenaire',
    removeAssociate: 'Supprimer le partenaire',
    book: 'Réserve',
    myBookings: 'Mes réservations',
    expiredBookings: 'Réservations expirées',
    lastMinuteBooking: 'Ceci est une réservation de dernière minute',
    lastMinuteBookingExpl:
      'Les réservations de dernière minute ne sont pas prises en compte dans les restrictions du nombre de réservations',
    fullyBooked: 'Complètement réservé',
    cancelBooking: 'Annuler la réservation',
    bookingNumber: 'Numéro de réservation',
    clientNumber: 'Numéro de client',
    passwordHint: 'Le mot de passe propre à cette plage horaire',
    sendConfirmation: 'Envoyer un e-mail de confirmation',
    acceptContact: "J'autorise la salle de gym à me contacter en cas de modification de ma réservation",
    checkinTime: "Heure d'enregistrement",
    checkout: 'Check-out',
    checkOutTablet:
      "Entrez l'adresse e-mail utilisée pour votre réservation pour vérifier. Tous les partenaires de votre réservation sont également vérifiés.",
    emails: {
      confirmation: {
        subject: 'Réservation à {gym_name} confirmée',
        confirmed: 'Réservation confirmée',
        activity_at: '{activity} à {gym_name}',
        confirmation: 'Votre réservation pour {area_name} à {gym_name} a été confirmée.',
        checkin_time: "Heure d'enregistrement",
        booking_number: 'Numéro de réservation',
        manage_online: 'Vous pouvez gérer vos réservations en ligne via TopLogger:',
        manage_booking: 'Gérer la réservation',
        thanks: 'Merci!',
      },
      no_show: {
        subject: 'Réservation manquée',
        you_are_marked: 'Votre réservation à {gym_name} a été marquée comme une non-présentation.',
        time_was: "L'heure d'enregistrement était le {date} entre {time_start} et {time_end}.",
        cancel: 'Vous pouvez annuler vos réservations à tout moment en vous connectant à votre compte TopLogger.',
        questions: 'Si vous avez des questions, veuillez contacter directement le centre.',
      },
      checkoutReminder: {
        subject: 'Rappel pour vérifier',
        reminder: 'Ceci est un aimable rappel de {gym_name} à vérifier au bureau.',
        time_is: 'Votre heure de paiement est le {date} à {time_end}.',
      },
    },
  },
  help: {
    title: 'Aide | TopLogger',
    description: "Comment ca marche. Règles pour l'escalade, top et logger vos réalisations dans TopLogger.",
    faq: {
      header: 'FAQ',
      subhead: 'Questions frequentes',
      example: 'Par exemple',
      qGradeCalc: 'Comment mon niveau ce fait calculer?',
      aGradeCalc: 'Votre niveau est calculer avec le top 10 de vos réalisations les dernier 2 mois.',
      aGradeCalcEx1:
        'Vos dix meilleures ascensions contient cinq 6a et cinq 6a⁺, cela veut dire que ton niveau est exactement entre 6a et 6a⁺, tu est donc a 50% pour obtenir le niveau de 6a⁺, ton niveau est 6a et 50%.',
      aGradeCalcEx2:
        "Vos dix meilleures ascensions contiennent un 6a et neuf 6a⁺. Cela signifie que votre niveau est presque 6a⁺: vous n'avez besoin que d'un autre. Vous êtes maintenant à 90% en passe de devenir un grimpeur de 6a⁺, votre note actuelle est donc '6a avec 90%'.",
      showPoints: 'Montrer le système de points',
      qTopFlash: 'Quelle est la difference entre Top et Flash?',
      qTopFlashRp: "Qu'est-ce que AT, FL et AV?",
      qCareTopFl: 'Pourquoi est-ce que Top ou Flash est important pour moi?',
      aCareTopFl:
        'Le Flash rapporte plus de points: un bloc Flash compte pour le calcul de ton niveau comme le bloc a un plus en difficulté.',
      aCareTopFlEx1:
        'Vous avez Flashé un 6a. Dans le calcul de votre niveau cela compte comme si vous aviez grimpé un 6a⁺.',
      aCareTopFlEx2:
        'Vous avez Flashé un 6c⁺. Dans le calcul de votre niveau cela compte comme si vous aviez grimpé un 7a.',
      qCareTopFlRp: 'Pourquoi est-ce que apres travail, Flash ou à vue est important pour moi?',
      aCareTopFlRp:
        'Le Flash et à vue rapportent plus de points: un voie Flash compte pour le calcul de ton niveau commeune voie avec un plus en difficulté. Une voie à vue rapporte même 2 point en plus.',
      aCareTopFlRpEx1:
        'Vous avez Flashé un 6a. Dans le calcul de votre niveau cela compte comme si vous aviez grimpé un 6a⁺.',
      aCareTopFlRpEx2:
        'Vous avez grimpé un 6a à vue. Dans le calcul de votre niveau cela compte comme si vous aviez grimpé un 6b.',
      aCareTopFlRpEx3:
        'Vous avez Flashé un 6c⁺. Dans le calcul de votre niveau cela compte comme si vous aviez grimpé un 7a.',
      aCareTopFlRpEx4:
        'Vous avez grimpé un 6c⁺ à vue. Dans le calcul de votre niveau cela compte comme si vous aviez grimpé un 7a⁺.',
      qScoreTime: 'Combien de temps mes logs sont compté dans mon classement?',
      aScoreTimeGrade:
        'Tes 10 meilleurs ascensions des deux dernier mois comptes dans ton classement. Peut importe si la route de ton log est enlevé.',
      aScoreTimeCount:
        'Tous les Logs des voies qui sonts actuellement dans votre salle comptent dans votre classement. Si une voie est enlevé, toute personne qui onts réaliser cette voie sacs dans le classement.',
      qGradeStability: 'Quel est ce point de couleur?',
    },
    guide: {
      header: "Guide de débutant pour l'escalade",
      subhead: 'Voici les règles du jeux',
      intro:
        'Le bloc consiste à sortir en utilisant une seule couleur. Mais il y as des règles. Voici une coutre explication:',
      starting: 'Commencer',
      startingExpl1:
        'Commencer avec vos mains sur les prises avec les tirets. Placez vos pieds sur le mur en position statique avant de commencer à grimper. Donc ne sautez pas tendit que cest explecitement écrit dans la description du bloc.',
      startingExpl2:
        "Il peut y avoir plusiers conditions, telle que le départ assis, ce qui signifie que vous devez partir d'une position assise sur le tapis.",
      climbing: 'Grimper',
      climbingExpl: "Grimper est 'simple': tu peut utiliser qu'une couleur.",
      topping: 'Réaliser',
      toppingExpl:
        "Vous pouvez dire que vous avez 'réalisé' un bloc si vous avez tenu la prise de top a deux mains pendant 3 secondes.",
      logging: 'Logger',
      loggingExpl: 'Il y as 3 façons de logger:',
      loggingOs: "À vue (AV) <small>premier essai, pas d'information</small>",
      loggingOsExpl:
        "Si vous sorté la voie lors de votre premiere tentative sans d'infomations des autres, cela s'appele 'à vue'",
      loggingFl: 'Flash (FL) <small>premiers essais, avec beta</small>',
      loggingFlExpl:
        "Si vous avez sorti la voie lors d'une premiere tentative, mais vous aviez des informations sur la façon dont cela devrait (ou ne devrait pas) ca s'appele \"Flash\" C'est le cas par exemple, si vous avez déjà vu quelqu'un essayer le bloc ou si quelqu'un vous as dit comment le faire.",
      loggingRp: 'Après travail (AT) <small>le reste</small>',
      loggingRpExpl:
        "Si vous avez sorti le bloc, mais pas lors de votre première tentative, cela s'appelle 'après travail'.",
    },
  },
  contact: {
    title: 'Contact | TopLogger',
    description: 'Contacter et rendre Toplogger disponible dans votre salle.',
    header: 'Contact',
    subhead: 'Que pouvons-nous faire les uns pour les autres',
    wantInGym: 'Voulez-vous que Toplogger soit disponible dans votre salle?',
    sendMail: 'Il suffit de nous envoyer un email:',
  },
  feedback: {
    title: 'Commentaires | TopLogger',
    description: 'Donnez votre avis sur Toplogger.',
    header: 'Donner de la rétroaction',
    subhead: 'Proposer des améliorations',
    forGym: 'Commentaires pour {gymName}',
    forApp: "Commentaires pour l'application",
    explanation: "Quelque chose ne va pas ou avez-vous une idée d'amélioration?",
    explanationGym: "Quelque chose ne vas pas avec l'application ou avez-vous une idée d'amélioration.",
    gymFeedbackMsg: 'Pour les voies manquant ou les dates erronées, vous devriez mieux contacter votre salle.',
    yourFeedback: 'Vos commentaires',
    yourFeedbackSub: 'Recevoir des réponses',
    message: 'Message',
    feedbackGym: 'Commentaires de gym',
    feedbackApp: "Commentaires sur l'application",
    reply: 'Réponse',
    retract: 'Révoquer',
    inputLabel: 'Écrire votre feedback (anonyme)',
    successMsg: 'Super! Merci de votre feedback!',
  },
  terms: {
    title: 'Conditions | TopLogger',
    description: "Conditions d'utilisation et politique de confidentialité de TopLogger",
  },
  components: {
    gradesChart: {
      header: 'Table de conversion en niveau',
      explanation: 'Points relatifs aux différents sytèmes de gradation',
    },
  },
  $vuetify, // Do not translate me please :)
}
